
import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DateTime } from '../../dateTime';
import { Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Location } from '@angular/common';
import { ReportService } from '../services/report.service';
import { HeaderComponent } from '../header/header.component';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-casinoresult',
  templateUrl: './casinoresult.component.html',
  styleUrls: ['./casinoresult.component.scss'],
  providers: [HeaderComponent],
})
export class CasinoresultComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  modalRef: BsModalRef;
  dtOptions: DataTables.Settings = {
    "lengthChange": false,
    "ordering": false,
    "paging": false,
    "searching": false
  };
  dtTrigger: Subject<any> = new Subject();
  userData: any;
  openBetData: any=[];
  pl: any;
  commission: any;
  netPl: any;
  roundId:any
  moment: any = moment;
  todayDate: Date = new Date()
  parentList: any;
  userParentName: any;
  adminDetails: any;
  type: any;
  filterParam: any = [];
  openBetFilter: boolean = false;
  deleteBets: boolean = false;
  resultDeclare: boolean = false;
  casinoId: any;
  transactionPassword: any;
  abond: boolean;
  deleteBetId: any;
  deleteBetUserId: any;
  constructor( private cookie: CookieService,private head: HeaderComponent,private loginService: LoginService,private sport: SportService,private router: Router,private modalService: BsModalService, private locationBack: Location, private reportService: ReportService, private toastr: ToastrService) { }

  // dateTimePicker 
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  time: Date;
  date: Date;
  result:any;
  endDate: Date;
  endTime: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  startdateTime = new Date();
  enddateTime = new Date();
  searchMatch: any
  searchUser: any
  searchMarket: any
  searchSport: any
  searchSeries: any
  startAt: Date = new Date()
  openStartDate: Date = new Date()
  openEndDate: Date = new Date()
  SportList: any;
  SeriesList: any;
  MatchList: any;
  initialSportList: any;
  userlist: any = [
    { "user_name": "user1" },
    { "user_name": "user2" },
    { "user_name": "user3" },
    { "user_name": "user4" },
    { "user_name": "user5" },
  ]
  MarketList: any;
  itemsPerPage: number = 50;
  currentPage: number = 1;
  totalItems: number = 0;
  betType: any = "Bet Type"
  param
  todayDateTime:Date=new Date()
  seconds:boolean=true
  ngOnInit() {

    this.adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    this.type = this.adminDetails.user_type_id;
    this.getResult();
  
  
  
  }

  goToBack() {
    this.locationBack.back();
  }


  getResult() {
    let data = {
      
    }
   
    this.reportService.casinoResult(data).subscribe((res) => {
      if (res.status) {
       
        this.result=[res.data]
      
      } else {
        this.toastr.error(res.msg)
        this.openBetData=[]
        if(res.logout == true){
          this.head.logoutUser();
        }
      }
    })


  }

  declareCasioPendingResult(){

    let data = {
      "gameRoundId":this.roundId
    }
   
    this.reportService.redeclareCasinoResult(data).subscribe((res) => {
      if (res.status) {
        this.toastr.success(res.msg)
        this.getResult()
      
      } else {
        this.toastr.error(res.msg)
        this.openBetData=[]
        if(res.logout == true){
          this.head.logoutUser();
        }
      }
    })

  }

 openModalResult(resultModel: TemplateRef<any>, roundId) {
    this.roundId = roundId;
   
    this.modalRef = this.modalService.show(
      resultModel,
      Object.assign({}, { class: 'resultModel-modal modal-lg' })
    );
  }

  pageChange(event?: any) {
  
  }

  
  
}
