<app-header></app-header>
<!-- <app-sidebar></app-sidebar> -->

<main>
    <div class="full-wrap">
        <div class="report-page">
            <div class="closed-user inner-content">
                <div class="page-title">
                    <h3>Casino Pending Result</h3>
                    <div class="breadcrumb">
                        <a (click)="goToBack()" class="btn btn-secondary back-btn"><i class="fa fa-arrow-left"></i>Back</a>
                    </div>
                </div>

              
                <div class="report-table">
                    <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                       
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>
                    <table datatable class="table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Game Name</th>
                                <th>Game Id</th>
                                <th>Round Id</th>
                                <th>createdAt</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let r of result| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems };let i = index;">
                                <tr>
                                   <td>{{i+1}}</td>
                                   <td>{{r?.gameName}}</td>
                                   <td>{{r?.gameId}}</td>
                                   <td>{{r?.gameRoundId}}</td>
                                   <td>{{r?.createdAt|date:'medium'}}</td>
                                   <td>
                                   <button type="button" class="btn btn-danger"
                    (click)="openModalResult(resultModel,r?.gameRoundId)">Result Declare</button>
                                   </td>
                                </tr>
                            </ng-container>
                            <div *ngIf="result?.length == 0" style="text-align: center;">
                                No data avalible 
                            </div>
                        </tbody>

                    </table>
                    <div class="report-bottom-paginate">
                        <div class="col-inner item-perpage-select">
                            <label>Items per page:</label>

                            <select name="betType" class="form-select" [(ngModel)]="itemsPerPage"
                                [ngModelOptions]="{standalone: true}" aria-label="Default select example"
                                (ngModelChange)="pageChange()">
                                <option selected disabled></option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                       
                        <pagination-controls (pageChange)="pageChange($event)"
                        class="user-list-pagination" ></pagination-controls>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>

<ng-template #resultModel>
    <div class="modal-header">
        <h4 class="modal-title">Result Declare </h4>
        <a type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()"><i class="fa fa-times"></i></a>
    </div>
    <form>
        <div class="modal-body delete-bet">
            <div class="body-inner">
                <div class="form-list">
                    <label class="form-heading">Round Id</label>
                    <input type="text" placeholder="Password" value="{{roundId}}" readonly class="password-box form-attribute"
                       >
                </div>
                
            </div>
        </div>

        <div class="modal-footer">
            <div class="text-center">
                <button type="button" class="btn btn-info" (click)="modalRef.hide();">Cancel</button>
                <button type="button" (click)="declareCasioPendingResult()" class="btn btn-success"
                   >Submit</button>
               
            </div>
        </div>
    </form>
</ng-template>